export default [
  {
    header: 'Tài chính',
    resource: 'finance-function',
    action: 'read',
  },
  {
    title: 'Học phí',
    route: 'invoice-list',
    icon: 'FileTextIcon',
    resource: 'invoice',
    action: 'read',
  },
  {
    title: 'Biên lai thu tiền',
    route: 'income-expense-list',
    icon: 'DollarSignIcon',
    resource: 'income-expense',
    action: 'read',
  },
  {
    title: 'Tiền thừa',
    route: 'prepaid-list',
    icon: 'CreditCardIcon',
    resource: 'prepaid',
    action: 'read',
  },
  {
    title: 'Hóa đơn đầu vào',
    route: 'input-invoice-list',
    icon: 'SaveIcon',
    resource: 'input-invoice',
    action: 'read',
  },
  {
    title: 'Hóa đơn đầu ra',
    route: 'release-invoice-list',
    icon: 'ShareIcon',
    resource: 'release-invoice',
    action: 'read',
  },
];
