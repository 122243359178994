export default [
  {
    header: 'Thông tin trường học',
    resource: 'school-function',
    action: 'read',
  },
  {
    title: 'Cơ sở',
    route: 'school-list',
    icon: 'HomeIcon',
    resource: 'school',
    action: 'read',
  },
  {
    title: 'Lớp học',
    route: 'class-list',
    icon: 'BriefcaseIcon',
    resource: 'class',
    action: 'read',
  },
  {
    title: 'Học sinh',
    route: 'student-list',
    icon: 'UsersIcon',
    resource: 'student',
    action: 'read',
  },
  {
    title: 'Loại dịch vụ',
    route: 'fee-category-list',
    icon: 'PackageIcon',
    resource: 'fee-category',
    action: 'read',
  },
  {
    title: 'Dịch vụ',
    route: 'fee-list',
    icon: 'LayersIcon',
    resource: 'fee',
    action: 'read',
  }
];
